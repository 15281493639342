import React, { useEffect } from "react";
import Sidebar from "../common/Sidebar";
import Logout from "../common/Logout";
import { Link } from "react-router-dom";

const Dashboard = () => {
  useEffect(() => {
    document.title = "Dashboard";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="main_section">
        <div className="main_flex">
          <div className="main_flexL">
            <Sidebar />
          </div>
          <div className="main_flexR">
            <div className="right_title">
              <h1>Dashboard</h1>
              <Logout />
            </div>
            <div className="dash_QuickLinks">
              <div className="dash_inner">
                <h1>Welcome Admin</h1>
                <h2>Quick Links</h2>
                <div className="dash_flex">
                  <div className="dash_fInner">
                    <Link to="/all-users">
                      <button className="main_button">
                        All Users <i class="fas fa-long-arrow-alt-right"></i>
                      </button>
                    </Link>
                  </div>
                  <div className="dash_fInner">
                    <Link to="/all-packages">
                      <button className="main_button">
                        All Packages <i class="fas fa-long-arrow-alt-right"></i>
                      </button>
                    </Link>
                  </div>
                  <div className="dash_fInner">
                    <Link to="/faq">
                      <button className="main_button">
                        All FAQ's <i class="fas fa-long-arrow-alt-right"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
