import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../common/Sidebar";
import Pro from "../images/profile-avatar.png";
import "../customCss/AllUsers.css";
import DataService from "../services/data.service";
import LoadingBar from "react-top-loading-bar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Logout from "../common/Logout";
import parse from "html-react-parser";

const FaqList = () => {
  const ref = useRef(null);
  const [faq, setFaq] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleDelete = (item) => {
    DataService.deleteFaq(item?.id).then(
      () => {
        toast.success("Faq deleted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          getFaq();
        }, 500);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const getFaq = async () => {
    await DataService.getAllFaq().then((data) => {
      setfilteredData(data?.data?.data);
      setFaq(data?.data?.data);
      setLoading(false);
      ref.current.complete();
    });
  };
  const onChangeSearch = (e) => {
    if (e.target.value) {
      const result = faq.filter((value) => {
        return value?.heading
          ? value.heading.toLowerCase().includes(e.target.value.toLowerCase())
          : "";
      });
      setfilteredData(result);
    } else {
      setfilteredData(faq);
    }
  };

  useEffect(() => {
    document.title = "All Faq";
    window.scrollTo(0, 0);
    ref.current.continuousStart();
    getFaq();
  }, []);

  return (
    <div className="main_section">
      <div className="main_flex">
        <div className="main_flexL">
          <Sidebar />
        </div>
        <div className="main_flexR">
          <div className="right_title">
            <h1>FAQ's</h1>
            <Logout />
          </div>
          {loading && (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
          <LoadingBar color="#C952A0" ref={ref} height={5} shadow={true} />
          <div className="main_innerSec">
            <div className="filters_sec">
              <div className="filtersL">
                <div class="form-floating mb-3">
                  <input
                    type="search"
                    class="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    onChange={onChangeSearch}
                  />
                  <label for="floatingInput">Search Here..</label>
                </div>
              </div>
              <div className="filtersR" style={{ textAlign: "right" }}>
                <Link to="/add-faq">
                  <button className="main_button">Add New Faq</button>
                </Link>
              </div>
            </div>
            <div className="main_enteriesSec">
              <table className="main_table">
                <thead>
                  <tr>
                    <th scope="col" width="5%">
                      S. No.
                    </th>
                    <th scope="col" width="10%">
                      Question
                    </th>
                    <th scope="col" width="25%">
                      Answer
                    </th>
                    <th scope="col" width="15%" style={{ textAlign: "right" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData && filteredData.length > 0 ? (
                    filteredData.map((item, i) => {
                      return (
                        <>
                          <tr className="mEntry">
                            <td width="5%" className="Mindex">
                              <span>{i + 1}</span>
                            </td>
                            <td width="25%" className="Mname">
                              <p>{item?.heading}</p>
                            </td>
                            <td width="10%" className="Mgender">
                              <p>
                                {parse(
                                  item?.description
                                    .split(" ")
                                    .slice(0, 25)
                                    .join(" ")
                                )}
                              </p>
                            </td>
                            <td width="15%" className="Mactions">
                              <Link to={"/edit-faq/" + item?.id}>
                                <i class="fas fa-pencil-alt"></i>
                              </Link>
                              <i
                                onClick={() => handleDelete(item)}
                                class="fas fa-trash-alt"
                              ></i>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : loading ? (
                    ""
                  ) : (
                    <p>No Faq Found</p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqList;



