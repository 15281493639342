import React, { useEffect, useState, useRef } from "react";
import "../customCss/Profile.css";
import ProfileOne from "../images/profile-avatar.png";
import Verified from "../images/verified.jpg";
import DataService from "../services/data.service";
import moment from "moment";
import LoadingBar from "react-top-loading-bar";
import { useParams } from "react-router-dom";
import Sidebar from "../common/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import Logout from "../common/Logout";

const Profile = () => {
  const ref = useRef(null);
  const params = useParams();
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [allImages, setAllImages] = useState([]);

  const toggleImageSelection = (image) => {
    if (selectedImages.includes(image)) {
      setSelectedImages(selectedImages.filter((img) => img !== image));
    } else {
      setSelectedImages([...selectedImages, image]);
    }
  };

  const approveImage = (image) => {
    setMessage("");
    DataService.ApproveProfile(image.id).then(
      () => {
        toast.success("Approved Successfully!");
        // approveImage(image);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const rejectImage = (image) => {
    setMessage("");
    DataService.RejectProfile(image.id).then(
      () => {
        toast.success("Rejected Successfully!");
        // approveImage(image);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const approveSelectedImages = () => {
    selectedImages.forEach((image) => {
      approveImage(image);
    });
  };

  const rejectSelectedImages = () => {
    selectedImages.forEach((image) => {
      rejectImage(image);
    });
  };

  // const getImageGallery = async () => {
  //   await DataService.getGallery(params.id).then((data) => {
  //     setImages(data?.data?.data);
  //   });
  // };

  const getUserProfile = async () => {
    await DataService.getSingleProfile(params.id).then((data) => {
      setProfile(data?.data?.data);
      setAllImages(
        data?.data?.data?.images.filter((item) => item.is_verified === 0)
      );
      ref.current.complete();
    });
  };
  useEffect(() => {
    document.title = "Profile";
    window.scrollTo(0, 0);
    getUserProfile();
    // getImageGallery()
    ref.current.continuousStart();
  }, [params.id]);

  return (
    <>
      <div className="main_section">
        <div className="main_flex">
          <div className="main_flexL">
            <Sidebar />
          </div>
          <div className="main_flexR">
            <div className="right_title">
              <h1>Profile / {profile?.name}</h1>
              <Logout />
            </div>

            <LoadingBar color="#C952A0" ref={ref} height={5} shadow={true} />
            <section className="main_proflieSec">
              <div className="container">
                <div className="profileFlex">
                  <div className="profileFlexL">
                    <img
                      src={profile?.profile_path}
                      onError={(e) => (e.target.src = ProfileOne)}
                      alt=""
                    />
                    {/* <div className="gender_iop">
                      <i class="fas fa-venus"></i>
                    </div> */}
                  </div>
                  <div className="profileFlexR">
                    <div className="profile_title">
                      <div className="d-flex align-items-center justify-content-between">
                        <h2>
                          {profile?.name}
                          <i className="fas fa-circle"></i>
                        </h2>

                        {/* <div className="profile_upload">
                          <button className="main_button"
                            style={{ marginRight: "10px", padding: "10px 30px" }}>Approve profile</button>
                          <button className="main_button"
                            style={{ margin: "0", padding: "10px 30px" }}>Decline profile</button>
                        </div> */}
                        {/* <button
                    className="main_button"
                    style={{ margin: "0", padding: "10px 35px" }}
                  >
                    Edit Profile<i class="fas fa-pencil-alt"></i>
                  </button> */}
                      </div>
                      <p>
                        <i class="fas fa-map-marker-alt"></i> {profile?.country}
                      </p>
                      <p>{profile?.description}</p>
                    </div>
                  </div>
                </div>
                <div className="profile_descriptionSec">
                  <div className="profile_dflex">
                    {/* <div className="verified_badge">
                      <img src={Verified} alt="" />
                    </div> */}
                    <div className="profile_dFlexL">
                      <div className="profileEntry">
                        <p>
                          <i class="fas fa-envelope"></i> Email
                        </p>
                        <p>:</p>
                        <p>{profile?.email}</p>
                      </div>
                      <div className="profileEntry">
                        <p>
                          <i class="fas fa-user"></i> Gender
                        </p>
                        <p>:</p>
                        <p>
                          {profile?.gender === "male"
                            ? "Male"
                            : profile?.gender === "female"
                            ? "Female"
                            : "Other"}
                        </p>
                      </div>
                      <div className="profileEntry">
                        <p>
                          <i class="fas fa-birthday-cake"></i> Birth Date
                        </p>
                        <p>:</p>
                        <p>{moment(profile?.birthdate).format("LL")}</p>
                      </div>
                      <div className="profileEntry">
                        <p>
                          <i class="fas fa-map-marker-alt"></i> City
                        </p>
                        <p>:</p>
                        <p>
                          {profile?.city}, {profile?.postcode}
                        </p>
                      </div>
                      <div className="profileEntry">
                        <p>
                          <i class="fas fa-clock"></i> Timezone
                        </p>
                        <p>:</p>
                        <p>{profile?.timezone?.label}</p>
                      </div>
                      <div className="profileEntry">
                        <p>
                          <i class="fas fa-text-height"></i> Height
                        </p>
                        <p>:</p>
                        <p>{profile?.height}</p>
                      </div>
                    </div>
                    <div className="profile_dFlexR">
                      <div className="profileEntry">
                        <p>
                          <i class="fas fa-weight"></i> Weight
                        </p>
                        <p>:</p>
                        <p>{profile?.weight}Kg</p>
                      </div>
                      <div className="profileEntry">
                        <p>
                          <i class="fas fa-eye"></i> Eye Color
                        </p>
                        <p>:</p>
                        <p>{profile?.eye_color}</p>
                      </div>
                      <div className="profileEntry">
                        <p>
                          <i class="fas fa-tint"></i> Hair Color
                        </p>
                        <p>:</p>
                        <p>{profile?.hair_color}</p>
                      </div>
                      <div className="profileEntry">
                        <p>
                          <i class="fab fa-servicestack"></i> Hair Length
                        </p>
                        <p>:</p>
                        <p>{profile?.hair_length}</p>
                      </div>
                      <div className="profileEntry">
                        <p>
                          <i class="fas fa-ring"></i> Marital Status
                        </p>
                        <p>:</p>
                        <p>{profile?.marital_status}</p>
                      </div>
                      <div className="profileEntry">
                        <p>
                          <i class="fas fa-futbol"></i> Interests
                        </p>
                        <p>:</p>
                        <p>
                          {profile?.interests && profile?.interests.length > 0
                            ? profile?.interests?.map((item, i) => {
                                return item + ", ";
                              })
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="image_gallery">
                  <div className="get_all_images">
                  <div className="images-heading">
                        <h1 style={{padding:"10px 2px",fontSize:"18px",margin:"0px"}}>Unverified images to verify </h1>
                      </div>
                      <br />

                    <div>
                      <button
                        className="main_button"
                        style={{ marginRight: "10px", padding: "10px 30px" }}
                        onClick={approveSelectedImages}
                      >
                        Approve Selected
                      </button>
                      <button
                        className="main_button"
                        style={{ marginRight: "10px", padding: "10px 30px" }}
                        onClick={rejectSelectedImages}
                      >
                        Reject Selected
                      </button>
                      <button
                        className="main_button"
                        style={{ marginRight: "10px", padding: "10px 30px" }}
                        onClick={() => setSelectedImages([])}
                      >
                        Clear Selection
                      </button>
                      <br />
                    

                      <div className="images_upload_main">
                        {allImages && allImages.length > 0 ? ( // Check if images is defined and not empty
                          allImages.map((image) => (
                            <div className="image_upload" key={image.url}>
                              <img
                                src={`https://api.milfhub.co.uk/${image.path}`}
                                alt="Image"
                              />
                              <input
                                type="checkbox"
                                checked={selectedImages.includes(image)}
                                onChange={() => toggleImageSelection(image)}
                              />
                            </div>
                          ))
                        ) : (
                          <p>No images available</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
