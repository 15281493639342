import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Log from "../images/login.jpg";
import "../customCss/Login.css";
import Logo from "../images/mature_friends_logo.jpg";
import AuthService from "../services/auth.service";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const form = useRef();

  const handleLogin = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);

    AuthService.login(username, password).then(
      () => {
        toast.success("Login Successful!!");
        navigate("/dashboard");
      },
      (error) => {
        const resMessage = error.response.data.message;
        setLoading(false);
        setMessage(resMessage);
        toast.error(resMessage)
      }
    );
  };
  return (
    <>
      <section className="login_sec">
        <div className="login_nav">
          <img src={Logo} alt="" className="site_logo" />
        </div>
        <div className="login_flex">
          <div className="loginL">
            <img src={Log} alt="" />
          </div>
          <div className="loginR">
            <form ref={form} onSubmit={handleLogin}>
              <div className="login_formSec">
                <h1>Login</h1>
                <div class="form-floating mb-4">
                  <input
                    type="email"
                    class="form-control"
                    id="floatingInput"
                    placeholder=""
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <label for="floatingInput">Email address</label>
                </div>
                <div class="form-floating mb-4">
                  <input
                    type="password"
                    class="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label for="floatingPassword">Password</label>
                </div>
                <div className="form_field">
                  <button className="main_button" onClick={handleLogin}>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
