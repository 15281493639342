import React, { useRef, useEffect, useState } from "react";
import Sidebar from "../common/Sidebar";
import { Editor } from "@tinymce/tinymce-react";
import "../customCss/Main.css";
import DataService from "../services/data.service";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Logout from "../common/Logout";

const AddNewPackages = () => {

    const styles = {
        input: {
            opacity: "0%", // dont want to see it
            position: "absolute", // does not mess with other elements
        },
    };
    const navigate = useNavigate();
    const [credits, setCredits] = useState('');
    const [currency, setCurrency] = useState('');
    const [price, setPrice] = useState('');
    const [bonus, setBonus] = useState('');
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false); 


    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage("");
        const data = {};
        data.credits = credits;
        data.currency = currency;
        data.price = price;
        data.bonus = bonus;
        DataService.addPackages(data).then(
            () => {
                toast.success("Packages Added Successfully!")
                setTimeout(() => {
                    navigate('/all-packages')
                }, 2000)
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                toast.error(resMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        );

    };
    useEffect(() => {
        document.title = "Add New Blog";
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <div className="main_section">
                <div className="main_flex">
                    <div className="main_flexL">
                        <Sidebar />
                    </div>
                    <div className="main_flexR">
                        <div className="right_title">
                            <h1>Add Package</h1>
                            <Logout/>
                        </div>
                        <div className="inter_inner">
                            <div class="form-floating mb-3">
                                <input
                                    type="number"
                                    class="form-control"
                                    id="floatingInput"
                                    placeholder=""
                                    required
                                    onChange={(e) => setCredits(e.target.value)}
                                />
                                <label for="floatingInput">Credits</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="floatingInput"
                                    placeholder=""
                                    required
                                    onChange={(e) => setCurrency(e.target.value)}
                                />
                                <label for="floatingInput">Currency</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input
                                    type="number"
                                    class="form-control"
                                    id="floatingInput"
                                    placeholder=""
                                    required
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                                <label for="floatingInput">Prices</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input
                                    type="number"
                                    class="form-control"
                                    id="floatingInput"
                                    placeholder=""
                                    required
                                    onChange={(e) => setBonus(e.target.value)}
                                />
                                <label for="floatingInput">Bonus</label>
                            </div>

                            <div className="submit_sec">
                                <button className="main_button" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewPackages