import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../common/Sidebar";
import Pro from "../images/profile-avatar.png";
import "../customCss/AllUsers.css";
import DataService from "../services/data.service";
import LoadingBar from "react-top-loading-bar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Logout from "../common/Logout";
import parse from "html-react-parser";

const HelpDesk = () => {
  const ref = useRef(null);
  const [helpdesk, setHelpdesk] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getHelpdesk = async () => {
    await DataService.getAllHelpDesk().then((data) => {
      setfilteredData(data?.data?.data);
      setHelpdesk(data?.data?.data);
      setLoading(false);
      ref.current.complete();
    });
  };
  const onChangeSearch = (e) => {
    if (e.target.value) {
      const result = helpdesk.filter((value) => {
        return value?.category
          ? value.category.toLowerCase().includes(e.target.value.toLowerCase())
          : "";
      });
      setfilteredData(result);
    } else {
      setfilteredData(helpdesk);
    }
  };

  useEffect(() => {
    document.title = "All Helpdesk Queries";
    window.scrollTo(0, 0);
    ref.current.continuousStart();
    getHelpdesk();
  }, []);

  return (
    <div className="main_section">
      <div className="main_flex">
        <div className="main_flexL">
          <Sidebar />
        </div>
        <div className="main_flexR">
          <div className="right_title">
            <h1>HELPDESK</h1>
            <Logout />
          </div>
          {loading && (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
          <LoadingBar color="#C952A0" ref={ref} height={5} shadow={true} />
          <div className="main_innerSec">
            <div className="filters_sec">
              <div className="filtersL">
                <div class="form-floating mb-3">
                  <input
                    type="search"
                    class="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    onChange={onChangeSearch}
                  />
                  <label for="floatingInput">Search Here..</label>
                </div>
              </div>
              <div className="filtersR"></div>
            </div>
            <div className="main_enteriesSec">
              <table className="main_table">
                <thead>
                  <tr>
                    <th scope="col" width="5%">
                      S. No.
                    </th>
                    <th scope="col" width="10%">
                      Category
                    </th>
                    <th scope="col" width="25%">
                      Question
                    </th>
                    <th scope="col" width="15%">
                      By User
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData && filteredData.length > 0 ? (
                    filteredData.map((item, i) => {
                      return (
                        <>
                          <tr className="mEntry">
                            <td width="5%" className="Mindex">
                              <span>{i + 1}</span>
                            </td>
                            <td width="25%" className="Mname">
                              <p>{item?.category}</p>
                            </td>
                            <td width="10%" className="Mgender">
                              <p>
                                {item?.description
                                  .split(" ")
                                  .slice(0, 20)
                                  .join(" ")}
                              </p>
                            </td>
                            <td width="10%" className="Mgender">
                              <p>
                                {item?.user?.name}
                              </p>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : loading ? (
                    ""
                  ) : (
                    <p>No HelpDesk Queries Found</p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpDesk;
