import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../common/Sidebar";
import Pro from "../images/profile-avatar.png";
import "../customCss/AllUsers.css";
import DataService from "../services/data.service";
import LoadingBar from "react-top-loading-bar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Logout from "../common/Logout";

const AllUsers = () => {
  const ref = useRef(null);
  const [users, setUsers] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleDelete = (item) => {
    DataService.deleteUser(item?.id).then(
      () => {
        toast.success("User deleted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 500);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const getAllUsers = async (limit, page) => {
    setLoading(true);
    await DataService.getAllUsers(limit, page).then((data) => {
      const totalUsers = data?.data?.data?.total_users;
      const totalPages = Math.ceil(totalUsers / limit);
      setfilteredData(data?.data?.data?.users);
      setUsers(data?.data?.data?.users);
      setLoading(false);
      setTotalPages(totalPages);
      ref.current.complete();
    });
  };
  const handlePageChange = async (page) => {
    setCurrentPage(page);
    await getAllUsers(20, page);
  };

  const renderPaginationButtons = () => {
    console.log(totalPages);
    const maxVisibleButtons = 5;
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisibleButtons / 2)
    );
    const endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1);

    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? "activated" : ""}
          disabled={loading}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="user_pagination">
        {currentPage > 1 && (
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={loading}
          >
            <i class="fas fa-chevron-left"></i>
          </button>
        )}
        {buttons}
        {currentPage < totalPages && (
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={loading}
          >
            <i class="fas fa-chevron-right"></i>
          </button>
        )}
      </div>
    );
  };
  const onChangeSearch = (e) => {
    if (e.target.value) {
      const result = users.filter((value) => {
        return value?.name
          ? value.name.toLowerCase().includes(e.target.value.toLowerCase())
          : "";
      });
      setfilteredData(result);
    } else {
      setfilteredData(users);
    }
  };
  const onSelectChangeSearch = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue !== "") {
      const result = users.filter((value) => {
        return value.is_verified == selectedValue;
      });
      setfilteredData(result);
    } else {
      setfilteredData(users);
    }
  };

  useEffect(() => {
    document.title = "All Users";
    window.scrollTo(0, 0);
    ref.current.continuousStart();
    getAllUsers(20, 1);
  }, []);
  return (
    <div className="main_section">
      <div className="main_flex">
        <div className="main_flexL">
          <Sidebar />
        </div>
        <div className="main_flexR">
          <div className="right_title">
            <h1>All Users</h1>
            <Logout />
          </div>

          <LoadingBar color="#C952A0" ref={ref} height={5} shadow={true} />
          <div className="main_innerSec">
            <div className="filters_sec">
              <div className="filtersL">
                <div class="form-floating mb-3">
                  <input
                    type="search"
                    class="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    onChange={onChangeSearch}
                  />
                  <label for="floatingInput">Search Here..</label>
                </div>
              </div>
              <div className="filtersR">
                <select
                  class="form-select"
                  onChange={onSelectChangeSearch}
                  aria-label="Default select example"
                >
                  <option value="">Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div>
            <div className="main_enteriesSec">
              <table className="main_table">
                <thead>
                  <tr>
                    <th scope="col" width="5%">
                      S. No.
                    </th>
                    <th scope="col" width="10%">
                      Image
                    </th>
                    <th scope="col" width="25%">
                      Name
                    </th>
                    <th scope="col" width="10%">
                      Gender
                    </th>
                    <th scope="col" width="10%">
                      Marital
                    </th>
                    <th scope="col" width="15%">
                      Location
                    </th>
                    <th scope="col" width="15%" style={{ textAlign: "center" }}>
                      Status
                    </th>
                    <th scope="col" width="15%" style={{ textAlign: "right" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                {loading && (
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                )}
                <tbody>
                  {filteredData && filteredData.length > 0 ? (
                    filteredData.map((item, i) => {
                      return (
                        <>
                          <tr className="mEntry">
                            <td width="5%" className="Mindex">
                              <span>{i + 1}</span>
                            </td>
                            <td width="10%" className="Mthumb">
                              <img
                                src={item?.profile_path}
                                onError={(e) => (e.target.src = Pro)}
                                alt=""
                              />
                            </td>
                            <td width="25%" className="Mname">
                              <p>{item?.name}</p>
                            </td>
                            <td width="10%" className="Mgender">
                              <p>{item?.gender}</p>
                            </td>
                            <td width="10%" className="Mmarital">
                              <p>{item?.marital_status}</p>
                            </td>
                            <td width="15%" className="Mcountry">
                              <p>{item?.country}</p>
                            </td>
                            {item?.is_verified === 1 ? (
                              <td width="10%" className="Mstatus">
                                <p>Active</p>
                              </td>
                            ) : (
                              <td width="10%" className="Mstatus MstatusRed">
                                <p>Inactive</p>
                              </td>
                            )}
                            <td width="15%" className="Mactions">
                              <Link to={"/profile/" + item?.id}>
                                <i class="fas fa-eye"></i>
                              </Link>
                              {/* <button ><i class="fas fa-trash-alt"></i></button> */}
                              {/* <i class="fas fa-pencil-alt"></i> */}
                              <i
                                onClick={() => handleDelete(item)}
                                class="fas fa-trash-alt"
                              ></i>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : loading ? (
                    ""
                  ) : (
                    <p>No Users Found</p>
                  )}
                </tbody>
              </table>
              {renderPaginationButtons()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
