import React, { useState } from "react";
import "../customCss/Sidebar.css";
import Log from "../images/mature_friends_logo.jpg";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const url = window.location.href;

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  return (
    <>
      <aside>
        <div className="main_sidebar">
          <div className="sidebar_top">
            <img src={Log} alt="" />
            <h5>Logged in as:</h5>
            <h6>Admin</h6>
          </div>
          <div className="sidebar_body">
            <ul>
              <li className={url.includes("all-users") ? "activated" : ""}>
                <Link to="/all-users">
                  <i class="fas fa-users"></i>All Users
                </Link>
              </li>

              <li>
                <div
                  style={{ display: "flex", alignContent: "center" }}
                  className="New_drop"
                  onClick={toggleDropdown}
                >
                  <i className="fab fa-accusoft"></i>Home Banners
                  <i
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      marginLeft: "auto",
                    }}
                    className={`fas fa-chevron-${
                      isDropdownOpen ? "up" : "down"
                    }`}
                  />
                </div>
              </li>
              {isDropdownOpen && (
                <ul>
                  <li className={url.includes("home-page") ? "activated" : ""}>
                    <Link to="/home-page">Home Top Banner</Link>
                  </li>
                  <li
                    className={
                      url.includes("home-page-middle") ? "activated" : ""
                    }
                  >
                    <Link to="/home-page-middle">Home Middle Banner</Link>
                  </li>
                  <li
                    className={
                      url.includes("home-page-second") ? "activated" : ""
                    }
                  >
                    <Link to="/home-page-second">Home Second Last Banner</Link>
                  </li>
                  <li
                    className={
                      url.includes("home-page-last") ? "activated" : ""
                    }
                  >
                    <Link to="/home-page-last">Home Last Banner</Link>
                  </li>
                </ul>
              )}

              {/* <li className={url.includes("home-page") ? "activated" : ""}>
                <Link to="/home-page">
                  <i class="fab fa-accusoft"></i>Home Top Banner
                </Link>
              </li>
              <li className={url.includes("home-page-middle") ? "activated" : ""}>
                <Link to="/home-page-middle">
                  <i class="fab fa-accusoft"></i>Home Middle Banner
                </Link>
              </li>
              <li className={url.includes("home-page-second") ? "activated" : ""}>
                <Link to="/home-page-second">
                  <i class="fab fa-accusoft"></i>Home Second Last Banner
                </Link>
              </li>
              <li className={url.includes("home-page-last") ? "activated" : ""}>
                <Link to="/home-page-last">
                  <i class="fab fa-accusoft"></i>Home Last Banner
                </Link>
              </li> */}
              <li className={url.includes("about-us") ? "activated" : ""}>
                <Link to="/about-us">
                  <i class="fab fa-accusoft"></i>About Us
                </Link>
              </li>
              {/* <li className={url.includes("contact-us") ? "activated" : ""}>
                <Link to="/contact-us">
                  <i class="far fa-address-card"></i>Contact Us
                </Link>
              </li> */}
              {/* <li className={url.includes("all-blogs") || url.includes("add-blog") || url.includes("edit-blog") ? "activated" : ""}>
                <Link to="/all-blogs">
                  <i class="fas fa-pen-square"></i>Blogs
                </Link>
              </li> */}
              <li className={url.includes("privacy-policy") ? "activated" : ""}>
                <Link to="/privacy-policy">
                  <i class="fas fa-file-contract"></i>Privacy Policy
                </Link>
              </li>
              <li
                className={url.includes("terms-conditions") ? "activated" : ""}
              >
                <Link to="/terms-conditions">
                  <i class="fas fa-file-contract"></i>Terms &amp; Conditions
                </Link>
              </li>
              <li className={url.includes("gdpr") ? "activated" : ""}>
                <Link to="/gdpr">
                  <i class="fas fa-file-contract"></i>GDPR
                </Link>
              </li>
              <li className={url.includes("faq") ? "activated" : ""}>
                <Link to="/faq">
                  <i class="fas fa-file-contract"></i>FAQ's
                </Link>
              </li>
              <li className={url.includes("helpdesk") ? "activated" : ""}>
                <Link to="/helpdesk">
                  <i class="fas fa-file-contract"></i>HelpDesk Queries
                </Link>
              </li>
              <li className={url.includes("social-links") ? "activated" : ""}>
                <Link to="/social-links">
                  <i class="fas fa-share-alt"></i>Social Links
                </Link>
              </li>
              <li className={url.includes("all-packages") ? "activated" : ""}>
                <Link to="/all-packages">
                  <i class="fas fa-box-open"></i>Packages
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
