import React, { useEffect, useState, useRef } from "react";
import "../customCss/AllBlogs.css";
import Sidebar from "../common/Sidebar";
import "../customCss/Main.css";
import "../customCss/AllBlogs.css";
import { Link } from "react-router-dom";
import Thumb from "../images/profile_banner.jpg";
import DataService from "../services/data.service";
import moment from "moment/moment";
import LoadingBar from "react-top-loading-bar";
import Logout from "../common/Logout";


const AllBlogs = () => {
  const ref = useRef(null);

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);


  const getAllBlogs = async () => {
    await DataService.getAllBlogs().then((data) => {
      setBlogs(data?.data?.data);
      setLoading(false)
      ref.current.complete();
    });
  };
  useEffect(() => {
    document.title = "All Blogs";
    window.scrollTo(0, 0);
    ref.current.continuousStart();
    getAllBlogs();
  }, []);
  return (
    <>
      <div className="main_section">
        <div className="main_flex">
          <div className="main_flexL">
            <Sidebar />
          </div>
          <div className="main_flexR">
            <div className="right_title">
              <h1>Blogs</h1>
              <Logout/>
            </div>
            {loading && (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
          <LoadingBar color="#C952A0" ref={ref} height={5} shadow={true} />
            <div className="inter_inner">
              <div className="add_new">
                <Link to="/add-blog">
                  <button className="main_button">Add New Blog</button>
                </Link>
              </div>
              <div className="main_blogFlex">
                {blogs && blogs.length > 0 ? (
                  blogs.map((item, index) => {
                    return (
                      <>
                        <div className="blogInner">
                          <div className="blogThumb">
                            {item?.images  ? (
                              <img
                                src={
                                  "https://api.milfhub.co.uk/" +
                                  item?.images[0].path
                                }
                                alt=""
                              />
                            ) : (
                              <img src={Thumb} alt="" />
                            )}
                          </div>
                          <div className="blogBody">
                            <span>
                              <i class="far fa-calendar-alt"></i> {moment(item?.created_at).format('LL')} /{" "}
                              <i class="fas fa-user"></i> Admin
                            </span>
                            <h3>{item?.heading}</h3>
                            <p dangerouslySetInnerHTML={{__html: item?.description}}></p>
                            <Link to={"/edit-blog/"+item?.id}>
                              <button className="main_button">
                                Edit <i class="fas fa-pencil-alt"></i>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) :  loading ? "" :
                  (
                    <p>No Blogs Found</p>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllBlogs;
