import React, { useRef, useEffect, useState } from "react";
import Sidebar from "../common/Sidebar";
import { Editor } from "@tinymce/tinymce-react";
import "../customCss/Main.css";
import DataService from "../services/data.service";
import { ToastContainer, toast } from "react-toastify";
import Logout from "../common/Logout";

const HomePageSecond = () => {
    const editorRef = useRef(null);
    const editorRefTwo = useRef(null);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [topsideTitle, setTopSideTitle] = useState("");
    const [topbottomTitle, setTopBottomTitle] = useState("");
    const [topImages, setTopImages] = useState("");
    const [topData, setTopData] = useState([])
    const [file, setFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    


    const inputFileRef = useRef();
    const imgRef = useRef();
    const onFileChangeCapture = (e) => {
      const file = e.target.files[0];
      setFile(e.target.files);
      const reader = new FileReader();
      const url = reader.readAsDataURL(file);
      reader.onloadend = function (theFile) {
        var image = new Image();
        image.src = theFile.target.result;
        if (imgRef.current) {
          imgRef.current.src = image.src;
        }
      };
    };
    const triggerFile = () => {
      inputFileRef.current.click();
    };

    const handleSubmitTop = (e) => {
      e.preventDefault();
      setMessage("");
      const data = new FormData();

      if(topData){
        data.append("_id",topData.id)
      }
      if (file && file.length > 0) {
        data.append("images[]", file[0]);
      }
      data.append("heading",topsideTitle)
      data.append("description",editorRef.current.getContent())

      
      // data.BottomHeading = topbottomTitle;
      // data.BottomDescription = editorRefTwo.current.getContent();
      DataService.addSecond(data).then(
        () => {
          toast.success("Data Added Successfully!")
          // setTimeout(() => {
          //   window.location.reload();
          // },2000)
        },
        (error) => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          toast.error(resMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
    };



 


    const getTop = async() => {
      await DataService.getSecondLast().then((data) => {
          const catData = data.data.data[0];
          setTopSideTitle(catData.heading)
          setTopBottomTitle(catData.BottomHeading)
          setTopImages(catData.images)
          setTopData(catData);
      });
  }
 
 
    useEffect(() => {
      document.title = "Home Page";
      window.scrollTo(0, 0);
      getTop();
      // getMiddle();
      // getSecondLast();
      // getLast()
    }, []);

  return (
    <div className="main_section">
    <div className="main_flex">
      <div className="main_flexL">
        <Sidebar />
      </div>
      <div className="main_flexR">
        <div className="right_title">
          <h1>Home page second last</h1>
          <Logout/>
        </div>
        <div className="inter_inner">
            <h2>Second Last Section</h2>
            <label for="floatingInput">Second Last Section Heading</label>

          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="floatingInput"
              placeholder=""
              required
              defaultValue={topData?.heading}
              value={topsideTitle}
              onChange={(e) => setTopSideTitle(e.target.value)}
            />
          </div>
          <div className="form_field">
            <label>Second Last Section Description</label>
            <Editor
              apiKey="ncusgccdwi657qdwg4drriodgbxgy3r0f695gd5i4przol9q"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={topData?.description}
              init={{
                height: 500,
                menubar: true,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div>
          <div className="row align-items-center">
                <div className="col-sm-4">
                {topData?.images ? (
                  <img
                    src={
                      "https://api.milfhub.co.uk/" +
                      topData.images[0]?.path
                    }
                    onClick={triggerFile}
                    style={{
                      height: "300px",
                      width: "300px",
                      objectFit: "cover",
                    }}
                    ref={imgRef}
                    alt=""
                  />
                ) : (
                  ""
                )}
                </div>
                   <div className="col-sm-8">
                  <div class="form-floating mb-3">
                    <input
                      type="file"
                      class="form-control"
                      id="floatingInput"
                      placeholder=""
                      required
                      ref={inputFileRef}
                      onChangeCapture={onFileChangeCapture}
                    />
                    <label for="floatingInput">Blog Image</label>
                  </div>
                </div>
             
              </div>
              <div className="submit_sec">
       
          </div>



          <div className="submit_sec">
            <button className="main_button" onClick={handleSubmitTop}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default HomePageSecond
