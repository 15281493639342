import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import AllUsers from "./pages/AllUsers";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import AllBlogs from "./pages/AllBlogs";
import AddNewBlog from "./pages/AddNewBlog";
import EditBlog from "./pages/EditBlog";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import SocialLinks from "./pages/SocialLinks";
import NotFound from "./pages/NotFound";
import Profile from "./pages/Profile";
import { RestrictedAccess } from "./private-component/RestrictedAccess";
import HomePage from "./pages/HomePage";
import HomePageMiddle from "./pages/HomePageMiddle";
import HomePageSecond from "./pages/HomePageSecond";
import HomePageLast from "./pages/HomePageLast";
import Packages from "./pages/Packages";
import AddNewPackages from "./pages/AddNewPackages";
import EditPackages from "./pages/EditPackages";
import Gdpr from "./pages/Gdpr";
import FaqList from "./pages/Faq";
import AddFaq from "./pages/AddFaq";
import EditFaq from "./pages/EditFaq";
import HelpDesk from "./pages/HelpDesk";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route element={<RestrictedAccess />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/all-users" element={<AllUsers />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/home-page" element={<HomePage />} />
          <Route path="/home-page-middle" element={<HomePageMiddle />} />
          <Route path="/home-page-second" element={<HomePageSecond />} />
          <Route path="/home-page-last" element={<HomePageLast />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/all-blogs" element={<AllBlogs />} />
          <Route path="/add-blog" element={<AddNewBlog />} />
          <Route path="/all-packages" element={<Packages />} />
          <Route path="/add-package" element={<AddNewPackages />} />
          <Route path="/edit-blog/:id" element={<EditBlog />} />
          <Route path="/edit-package/:id" element={<EditPackages />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/gdpr" element={<Gdpr />} />
          <Route path="/faq" element={<FaqList />} />
          <Route path="/helpdesk" element={<HelpDesk />} />
          <Route path="/add-faq" element={<AddFaq />} />
          <Route path="/edit-faq/:id" element={<EditFaq />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/social-links" element={<SocialLinks />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
