import React, { useRef, useEffect , useState} from "react";
import Sidebar from "../common/Sidebar";
import { Editor } from "@tinymce/tinymce-react";
import "../customCss/Main.css";
import DataService from "../services/data.service";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Logout from "../common/Logout";


const AddNewBlog = () => {
  const styles = {
    input: {
      opacity: "0%", // dont want to see it
      position: "absolute", // does not mess with other elements
    },
  };
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [blogTitle, setBlogTitle] = useState('');
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);  const [file, setFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const inputFileRef = useRef();
  const imgRef = useRef();
  const onFileChangeCapture = (e) => {
    const file = e.target.files[0];
    setFile(e.target.files);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = function (theFile) {
      var image = new Image();
      image.src = theFile.target.result;
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    const data = new FormData();
    if (file && file.length > 0) {
      setLoading(true);
      data.append("images[]", file[0]);
      if (uploadedFiles && uploadedFiles.length > 0) {
        uploadedFiles.some((file) => {
          data.append("images[]", file);
        });
      }
      data.append("heading", blogTitle);
      data.append("description", editorRef.current.getContent());
      DataService.addBlog(data).then(
        () => {
          toast.success("Blog Added Successfully!")
          setTimeout(() => {
            navigate('/all-blogs')
          }, 2000)
        },
        (error) => {
          // Extracting response message from error
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          
          setLoading(false);
          // Printing response message in the error toast
          toast.error(resMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
    } else {
      toast.error("Please Blog Image", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    document.title = "Add New Blog";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="main_section">
        <div className="main_flex">
          <div className="main_flexL">
            <Sidebar />
          </div>
          <div className="main_flexR">
            <div className="right_title">
              <h1>Add Blog</h1>
              <Logout/>
            </div>
            <div className="inter_inner">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  placeholder=""
                  required
                  onChange={(e) => setBlogTitle(e.target.value)}
                />
                <label for="floatingInput">Blog Title</label>
              </div>
              <div className="form_field">
                <label>Blog Content</label>
                <Editor
                  apiKey="ncusgccdwi657qdwg4drriodgbxgy3r0f695gd5i4przol9q"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue=""
                  init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "code",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "table",
                      "code",
                      "help",
                      "wordcount",
                    ],
                    toolbar:
                      "undo redo | blocks | " +
                      "bold italic forecolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style: "body { font-size:14px }",
                  }}
                />
              </div>
              <div class="form-floating mb-3">
                <input
                  type="file"
                  class="form-control"
                  id="floatingInput"
                  placeholder=""
                  required
                  ref={inputFileRef}
                  onChangeCapture={onFileChangeCapture}
                />
                <label for="floatingInput">Blog Image</label>
              </div>
              <div className="submit_sec">
                <button className="main_button" onClick={handleSubmit}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewBlog;
