import axios from "axios";
import AuthService from "../services/auth.service";

const API_URL =
  process.env.NODE_ENV != "production"
    ? "https://api.milfhub.co.uk/"
    : "https://api.milfhub.co.uk/";

axios.interceptors.request.use(function (config) {
  const token = AuthService.getCurrentUserTokken();
  config.headers.Authorization = "Bearer " + token;
  config.headers[
    "API-TOKEN"
  ] = `c1462debeb1e53644f768cbbc6a9562b73009-56a1e28ea706dc0bc38ba5ae990`;
  return config;
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("user");
      window.location.href = "/#/login";
      // Hace la solicitud de refresco de tokens
    }
    return Promise.reject(error);
  }
);
const addAboutUs = (data) => {
  return axios.post(API_URL + "api/admin/aboutAs", data);
};
const addContactUs = (data) => {
  return axios.post(API_URL + "api/admin/contactAs", data);
};
const addPrivacyPolicy = (data) => {
  return axios.post(API_URL + "api/admin/privacyPolicy", data);
};
const addGdpr = (data) => {
  return axios.post(API_URL + "api/admin/gdpr", data);
};
const addFaq = (data) => {
  return axios.post(API_URL + "api/admin/faq", data);
};
const addTermsConditions = (data) => {
  return axios.post(API_URL + "api/admin/termsCondition", data);
};
const addSocialLinks = (data) => {
  return axios.post(API_URL + "api/admin/socialLinks", data);
};
const addBlog = (data) => {
  return axios.post(API_URL + "api/admin/blog", data);
};
const getAllBlogs = () => {
  return axios.get(API_URL + "api/admin/getBlog");
};
const getOneBlog = (id) => {
  return axios.get(API_URL + "api/admin/getOneBlog/" + id);
};
const getAllUsers = (limit, page) => {
  return axios.get(API_URL + "api/admin/getAllUserByAdmin?limit="+limit+"&page="+page);
};
const getAboutUs = () => {
  return axios.get(API_URL + "api/admin/getAboutAs");
};
const getContactUs = () => {
  return axios.get(API_URL + "api/admin/getcontactAs");
};
const getSocialLinks = () => {
  return axios.get(API_URL + "api/admin/getSocialLinks");
};
const getPrivacyPoicy = () => {
  return axios.get(API_URL + "api/admin/getPrivacyPolicy");
};
const getGdpr = () => {
  return axios.get(API_URL + "api/admin/getGdpr");
};
const getAllFaq = () => {
  return axios.get(API_URL + "api/admin/faq");
};
const getAllHelpDesk = () => {
  return axios.get(API_URL + "api/admin/helpdesk");
};
const getTermsConditions = () => {
  return axios.get(API_URL + "api/admin/getTermsCondition");
};
const getSingleProfile = (id) => {
  return axios.get(API_URL + "api/admin/getOneUserByAdmin/" + id);
};
const getSingleFaq = (id) => {
  return axios.get(API_URL + "api/admin/faq/" + id);
};
const addTop = (data) => {
  return axios.post(API_URL + "api/admin/topBanner", data);
};
const getTopBanner = () => {
  return axios.get(API_URL + "api/admin/getTopBanner");
};
const getMiddleBanner = () => {
  return axios.get(API_URL + "api/admin/getMiddleBanner");
};
const getSecondLast = () => {
  return axios.get(API_URL + "api/admin/getSecondLastBanner");
};
const getLastBanner = () => {
  return axios.get(API_URL + "api/admin/getLastBanner");
};
const addLast = (data) => {
  return axios.post(API_URL + "api/admin/lastBanner", data);
};
const addSecond = (data) => {
  return axios.post(API_URL + "api/admin/secondLastBanner", data);
};
const addMiddle = (data) => {
  return axios.post(API_URL + "api/admin/middleBanner", data);
};

const getPackages = () => {
  return axios.get(API_URL + "api/admin/getAllCredit");
};

const addPackages = (data) => {
  return axios.post(API_URL + "api/admin/addcredit", data);
};
const deletePackages = (id) => {
  return axios.delete(API_URL + "api/admin/deleteCredit/" + id);
};
const deleteFaq = (id) => {
  return axios.delete(API_URL + "api/admin/entity/faq/" + id);
};
const deleteUser = (id) => {
  return axios.delete(API_URL + "api/user/delete/" + id);
};
const getSinglePackage = (id) => {
  return axios.get(API_URL + "api/admin/getCredit/" + id);
};
const UpdatePackage = (id, data) => {
  return axios.put(API_URL + "api/admin/updateCredit/" + id, data);
};
const ApproveProfile = (id) => {
  return axios.post(API_URL + "api/admin/approve-images/" + id);
};
const RejectProfile = (id) => {
  return axios.post(API_URL + "api/admin/reject-images/" + id);
};

const DataService = {
  deleteUser,
  RejectProfile,
  ApproveProfile,
  UpdatePackage,
  getSinglePackage,
  deletePackages,
  deleteFaq,
  addPackages,
  getPackages,
  getSingleFaq,
  addMiddle,
  addSecond,
  addLast,
  getLastBanner,
  getSecondLast,
  getMiddleBanner,
  getTopBanner,
  getAllFaq,
  addTop,
  addAboutUs,
  addContactUs,
  addPrivacyPolicy,
  addFaq,
  addGdpr,
  addTermsConditions,
  addSocialLinks,
  addBlog,
  getOneBlog,
  getAllBlogs,
  getAllUsers,
  getAboutUs,
  getContactUs,
  getSocialLinks,
  getPrivacyPoicy,
  getGdpr,
  getTermsConditions,
  getSingleProfile,
  getAllHelpDesk
};
export default DataService;
