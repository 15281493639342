import React , {useEffect, useState} from "react";
import Sidebar from "../common/Sidebar";
import "../customCss/Main.css";
import DataService from "../services/data.service";
import { ToastContainer, toast } from "react-toastify";
import Logout from "../common/Logout";

const SocialLinks = () => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [facebook, setFacebook] = useState('')
  const [instagram, setInstagram] = useState('')
  const [linkedin, setLinkedin] = useState('')
  const [twitter, setTwitter] = useState('')
  const [snapchat, setSnapchat] = useState('')
  const [socialLinks, setSocialLinks] = useState([])


  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    const data = {};
    if(socialLinks){
      data._id = socialLinks._id;
    }
    data.facebook = facebook;
    data.instagram = instagram;
    data.twitter = twitter;
    data.linkedin = linkedin;
    data.snapchat = snapchat;
    DataService.addSocialLinks(data).then(
      () => {
        toast.success("Data Added Successfully!!")
        setTimeout(() => {
          window.location.reload();
        },2000)
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const getSocialLinks = async() => {
    await DataService.getSocialLinks().then((data) => {
      const catData = data.data.data[0];
        setSocialLinks(catData);
        setFacebook(catData?.facebook)
        setInstagram(catData?.instagram)
        setLinkedin(catData?.linkedin)
         setSnapchat(catData?.snapchat)
         setTwitter(catData?.twitter)
    });
}
    useEffect(() => {
        document.title = "Social Links"
        window.scrollTo(0,0);
        getSocialLinks();
      },[])

  return (
    <>
      <div className="main_section">
        <div className="main_flex">
          <div className="main_flexL">
            <Sidebar />
          </div>
          <div className="main_flexR">
            <div className="right_title">
              <h1>Social Links</h1>
              <Logout/>
            </div>
            <div className="inter_inner">
              <div class="form-floating mb-4">
                <input
                  type="url"
                  class="form-control"
                  id="floatingInput"
                  placeholder=""
                  required
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                />
                <label for="floatingInput">Facebook</label>
              </div>
              <div class="form-floating mb-4">
                <input
                  type="url"
                  class="form-control"
                  id="floatingInput"
                  placeholder=""
                  required
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                />
                <label for="floatingInput">Twitter</label>
              </div>
              <div class="form-floating mb-4">
                <input
                  type="url"
                  class="form-control"
                  id="floatingInput"
                  placeholder=""
                  required
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                />
                <label for="floatingInput">Instagram</label>
              </div>
              <div class="form-floating mb-4">
                <input
                  type="url"
                  class="form-control"
                  id="floatingInput"
                  placeholder=""
                  required
                  value={linkedin}
                  onChange={(e) => setLinkedin(e.target.value)}
                />
                <label for="floatingInput">Linkedin</label>
              </div>
              <div class="form-floating mb-4">
                <input
                  type="url"
                  class="form-control"
                  id="floatingInput"
                  placeholder=""
                  required
                  value={snapchat}
                  onChange={(e) => setSnapchat(e.target.value)}
                />
                <label for="floatingInput">Snapchat</label>
              </div>

              <div className="submit_sec">
                <button className="main_button" onClick={handleSubmit}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialLinks;
