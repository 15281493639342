import React from 'react'
import { useNavigate } from 'react-router-dom';
const Logout = () => {
    const navigate = useNavigate()
    const logout = () => {
        localStorage.removeItem("token");
        navigate("/");
        window.location.reload();
      };

    return (
        <>
            <i onClick={logout} class="fas fa-sign-out-alt"></i>

        </>
    )
}

export default Logout