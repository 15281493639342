import React, { useRef, useEffect, useState } from "react";
import Sidebar from "../common/Sidebar";
import { Editor } from "@tinymce/tinymce-react";
import "../customCss/Main.css";
import DataService from "../services/data.service";
import { ToastContainer, toast } from "react-toastify";
import Logout from "../common/Logout";
import { useNavigate, useParams } from "react-router-dom";

const EditFaq = () => {
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [heading, setHeading] = useState("");
  const [faqData, setFaqData] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    const data = {};
    if (faqData) {
      data._id = faqData.id;
    }
    data.heading = heading;
    data.description = editorRef.current.getContent();
    DataService.addFaq(data).then(
      () => {
        toast.success("Data Added Successfully!!");
        setTimeout(() => {
          navigate("/faq");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const getFaqData = async () => {
    await DataService.getSingleFaq(params.id).then((data) => {
      const catData = data.data.data;
      setFaqData(catData);
      setHeading(catData?.heading);
    });
  };
  useEffect(() => {
    document.title = "Faq";
    window.scrollTo(0, 0);
    getFaqData();
  }, []);
  return (
    <>
      <div className="main_section">
        <div className="main_flex">
          <div className="main_flexL">
            <Sidebar />
          </div>
          <div className="main_flexR">
            <div className="right_title">
              <h1>Edit FAQ</h1>
              <Logout />
            </div>
            <div className="inter_inner">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  placeholder=""
                  required
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                />
                <label for="floatingInput">FAQ Question</label>
              </div>
              <div className="form_field">
                <label>FAQ Answer</label>
                <Editor
                  apiKey="ncusgccdwi657qdwg4drriodgbxgy3r0f695gd5i4przol9q"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={faqData?.description}
                  init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "code",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "table",
                      "code",
                      "help",
                      "wordcount",
                    ],
                    toolbar:
                      "undo redo | blocks | " +
                      "bold italic forecolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style: "body { font-size:14px }",
                  }}
                />
              </div>
              <div className="submit_sec">
                <button className="main_button" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFaq;
