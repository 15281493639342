import React , {useEffect, useState} from "react";
import Sidebar from "../common/Sidebar";
import "../customCss/Main.css";
import DataService from "../services/data.service";
import { ToastContainer, toast } from "react-toastify";
import Logout from "../common/Logout";

const ContactUs = () => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [conatctData, setContactData] = useState([])
  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    const data = {};
    if(conatctData){
      data._id = conatctData._id;
    }
    data.lat = lat;
    data.long = long;
    data.address = address;
    data.phoneNumber = phone;
    data.email = email;
    DataService.addContactUs(data).then(
      () => {
        toast.success("Data Added Successfully!!")
        setTimeout(() => {
          window.location.reload();
        },2000)
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const getContactData = async() => {
    await DataService.getContactUs().then((data) => {
      const catData = data.data.data;
        setContactData(catData)
        setLat(catData?.lat)
        setLong(catData?.long)
        setEmail(catData?.email)
        setPhone(catData?.phoneNumber)
        setAddress(catData?.address)
    });
}
    useEffect(() => {
        document.title = "Contact Us"
        window.scrollTo(0,0);
        getContactData();
      },[])

  return (
    <>
      <div className="main_section">
        <div className="main_flex">
          <div className="main_flexL">
            <Sidebar />
          </div>
          <div className="main_flexR">
            <div className="right_title">
              <h1>ContactUs</h1>
              <Logout/>
              
            </div>
            <div className="inter_inner">
              <div class="form-floating mb-4">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  placeholder=""
                  required
                  value={lat}
                  onChange={(e) => setLat(e.target.value)}
                />
                <label for="floatingInput">Latitude (Map Coordinates)</label>
              </div>
              <div class="form-floating mb-4">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  placeholder=""
                  required
                  value={long}
                  onChange={(e) => setLong(e.target.value)}
                />
                <label for="floatingInput">Longitude (Map Coordinates)</label>
              </div>
              <div class="form-floating mb-4">
                <input
                  type="email"
                  class="form-control"
                  id="floatingInput"
                  placeholder=""
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label for="floatingInput">Email</label>
              </div>
              <div class="form-floating mb-4">
                <input
                  type="tel"
                  class="form-control"
                  id="floatingInput"
                  placeholder=""
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <label for="floatingInput">Phone Number</label>
              </div>
              <div class="form-floating mb-4">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  placeholder=""
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <label for="floatingInput">Address</label>
              </div>

              <div className="submit_sec">
                <button className="main_button" onClick={handleSubmit}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
