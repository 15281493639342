import React, { useEffect, useState, useRef } from "react";
import "../customCss/AllBlogs.css";
import Sidebar from "../common/Sidebar";
import "../customCss/Main.css";
import "../customCss/AllBlogs.css";
import { Link } from "react-router-dom";
import Thumb from "../images/profile_banner.jpg";
import DataService from "../services/data.service";
import moment from "moment/moment";
import LoadingBar from "react-top-loading-bar";
import "../customCss/Packages.css"
import { ToastContainer, toast } from "react-toastify";
import Logout from "../common/Logout";

const Packages = () => {
    const ref = useRef(null);

    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(true);


    const getPlans = async () => {
        await DataService.getPackages().then((data) => {
            setPackages(data?.data?.data);
            setLoading(false)
            ref.current.complete();
        });
    };
    useEffect(() => {
        document.title = "All Packages";
        window.scrollTo(0, 0);
        ref.current.continuousStart();
        getPlans();
    }, []);


    const deletePackage = (e, item) =>{
        e.preventDefault();
        DataService.deletePackages(item?.id).then(
            () => {
                toast.success('Package deleted successfully!', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setTimeout(()=>{
                    window.location.reload(false);
                }, 500);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                    toast.error(resMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
            }
        );
         
      }
    return (
        <>
            <div className="main_section">
                <div className="main_flex">
                    <div className="main_flexL">
                        <Sidebar />
                    </div>
                    <div className="main_flexR">
                        <div className="right_title">
                            <h1>Packages</h1>
                            <Logout/>
                        </div>
                        {loading && (
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        )}
                        <LoadingBar color="#C952A0" ref={ref} height={5} shadow={true} />
                        <div className="inter_inner">
                            <div className="add_new">
                                <Link to="/add-package">
                                    <button className="main_button">Add New Packages</button>
                                </Link>
                            </div>
                            <div className="main_blogFlex">
                                {packages && packages.length > 0 ? (
                                    packages.map((item, index) => {
                                        return (
                                            <>
                                                <div className="blogInner">
                                                    <div className="Packages">
                                                        <div className="packages_inner">
                                                            <h1>Credits : </h1>
                                                            <p>{item.credits}</p>
                                                        </div>
                                                        <div className="packages_inner">
                                                            <h1>Currency : </h1>
                                                            <p>{item.currency}</p>
                                                        </div>
                                                        <div className="packages_inner">
                                                            <h1>Price : </h1>
                                                            <p>{item.price}</p>
                                                        </div>
                                                        <div className="packages_inner">
                                                            <h1>Bonus : </h1>
                                                            <p>{item.bonus}</p>
                                                        </div>
                                                    </div>
                                                    <div className="blogBody">

                                                        <div className="delete_packages">
                                                            <h2 style={{color:"red"}} onClick={(e) => {deletePackage(e, item) }}><i class="far fa-trash-alt"></i></h2>
                                                        </div>
                                                        <Link to={"/edit-package/" + item?.id}>
                                                            <button className="main_button">
                                                                Edit <i class="fas fa-pencil-alt"></i>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                ) : loading ? "" :
                                    (
                                        <p>No Packages Found</p>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Packages